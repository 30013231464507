<template>
    <div>
    </div>
</template>

<script>
export default {
    data() {
        return {
        };
    },
    methods: {
    }
};
</script>

<style scoped>

</style>
